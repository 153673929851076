import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const API = publicRuntimeConfig.PRODUCTION
	? publicRuntimeConfig.API_PRODUCTION
	: publicRuntimeConfig.API_DEVELOPMENT;
export const APP_NAME = publicRuntimeConfig.APP_NAME;
export const PRODUCTION = publicRuntimeConfig.PPRODUCTION;
export const DOMAIN = publicRuntimeConfig.PRODUCTION
	? publicRuntimeConfig.DOMAIN_PRODUCTION
	: publicRuntimeConfig.DOMAIN_DEVELOPMENT;
export const FIREBASE_API_KEY = publicRuntimeConfig.FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = publicRuntimeConfig.FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = publicRuntimeConfig.FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
	publicRuntimeConfig.FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
	publicRuntimeConfig.FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = publicRuntimeConfig.FIREBASE_APP_ID;
