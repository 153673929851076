import * as constants from './constants';
import { auth } from '../../../config/firebase';
import cookie from 'js-cookie';
export const signin = (email, password) => async (dispatch) => {
	auth
		.signInWithEmailAndPassword(email, password)
		.then((data) => {
			autenthicate(data, () =>
				dispatch({ type: constants.AUTH_SIGNIN, payload: data.user })
			);

			console.log(data);
			// autenthicate(data);
		})
		.catch((err) => {
			dispatch({ type: constants.ERROR, payload: err });
		});
};
export const signout = () => (dispatch) => {
	auth
		.signOut()
		.then((data) => {
			removeAutenthicate(() => {
				dispatch({ type: constants.AUTH_SIGNOUT });
			});
		})
		.catch((err) => {
			dispatch({ type: constants.ERROR, payload: err });
		});
};

export const setCookie = (key, value) => {
	if (process.browser) {
		cookie.set(key, value, {
			expires: 30,
		});
	}
};

export const removeCookie = (key) => {
	if (process.browser) {
		cookie.remove(key, {
			expires: 30,
		});
	}
};
// get cookie
export const getCookie = (key) => {
	if (process.browser) {
		return cookie.get(key);
	}
};
// localstorage
export const setLocalStorage = (key, value) => {
	if (process.browser) {
		localStorage.setItem(key, JSON.stringify(value));
	}
};

export const removeLocalStorage = (key) => {
	if (process.browser) {
		localStorage.removeItem(key);
	}
};
// autheticate user by pass data to cookie and localstorage
export const autenthicate = (data, next) => {
	setCookie('email', data.user.email);
	setCookie('token', data.user.uid);
	setLocalStorage('user', data.user);
	next();
};
export const removeAutenthicate = (next) => {
	removeCookie('email');
	removeCookie('token');
	removeLocalStorage('user');
	next();
};

export const isAuth = () => async (dispatch) => {
	const cookieChecked = getCookie('email');
	if (cookieChecked) {
		if (localStorage.getItem('user')) {
			dispatch({
				type: constants.AUTH_SIGNIN,
				payload: JSON.parse(localStorage.getItem('user')),
			});
			return JSON.parse(localStorage.getItem('user'));
		} else {
			return false;
		}
	}
};

// export const isAuth = () => {};
